<template>
  <v-container class="page_container">
    <banner-section :location="banner_location"/>
    <section v-if="Array.isArray(suppliers) && suppliers.length" class="d-flex justify-content-center flex-wrap">
      <div v-for="(supplier_item, index) in suppliers" :key="index">
        <router-link :to="{ name: supplier_router, params: { id: supplier_item.id } }" tag="a" exact>
          <SupplierCard class="pointer" :text="supplier_item.name" :logo="supplier_item.image"
            :bgColor="supplier_item.logo_background_color" :titleBgColor="supplier_item.logo_title_background"
            :fontColor="supplier_item.font_color">
          </SupplierCard>
        </router-link>
      </div>
    </section>
    <div class="position-relative">
      <section v-if="Array.isArray(suppliersWithBestsellers) && suppliersWithBestsellers.length && !loading" class="mt-20">
        <h5><b>{{ $t("Best Sellers") }}</b></h5>
        <div class="slick-carousel-container">
          <VueSlickCarousel v-bind:slidesToShow="itemPerSlide" 
            class="cus-slick-carousel"
            :class="suppliersWithBestsellers.length < 4 ? sliderDirection : ''" 
            :rtl="sliderDirection == 'rtl' ? true : false"
          >
            <div 
              v-for="(supplier_item, index) in suppliersWithBestsellers" 
              :key="index" 
              class="p-5"
            >
              <SellerCard
                :supplier_item="supplier_item" 
                :supplier_router="supplier_router"
                :product_router="product_router"
                item_key="bestsellers"
                @openInforPopup="handleOpenInforPopup"
              ></SellerCard>
            </div>
          </VueSlickCarousel>
        </div>
      </section>
      <section v-if="Array.isArray(suppliersWithSpecials) && suppliersWithSpecials.length && !loading" class="mt-20">
        <h5><b>{{ $t("Promotions") }}</b></h5>
        <div class="slick-carousel-container">
          <VueSlickCarousel v-bind:slidesToShow="itemPerSlide" 
            class="cus-slick-carousel tourism-carousel"
            :class="suppliersWithSpecials.length < 4 ? sliderDirection : ''" 
            :rtl="sliderDirection == 'rtl' ? true : false"
          >
            <div 
              v-for="(supplier_item, index) in suppliersWithSpecials" 
              :key="index" 
              class="p-5"
            >
              <SellerCard
                :supplier_item="supplier_item" 
                :supplier_router="supplier_router"
                :product_router="product_router"
                item_key="specials"
                @openInforPopup="handleOpenInforPopup"
              ></SellerCard>
            </div>
          </VueSlickCarousel>
        </div>
      </section>
      <InforPopup :inforData="inforData" :modal_key="modal_key"></InforPopup>
      <loading :active.sync="loading" :can-cancel="false" :is-full-page="false" style="height: 350px"></loading>
    </div>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import appConfig from '@/appConfig'
import BannerSection from "@/components/BannerSection";
import SupplierCard from '@/components/endUser/SupplierCard';
import SellerCard from '@/components/endUser/SellerCard';
import InforPopup from '@/components/communication/InforPopup.vue'

export default {
  name: 'SuppliersPage',
  components: {
    BannerSection,
    SupplierCard,
    SellerCard,
    InforPopup
  },
  props: {
    banner_location: {
      type: String,
      required: true
    },
    supplier_router: {
      type: String,
      required: true
    },
    product_router: {
      type: String,
      required: true
    },
    page_title: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      modal_key: +new Date(),
      inforData: ""
    }
  },
  computed: {
    ...mapState({
      loading: state => state.supplier.loading,
      supplier_items: state => state.supplier.supplier_items,
      suppliers_products: state => state.supplier.suppliers_products,
      product_bestseller_tag_id: state => state.supplier.product_bestseller_tag_id,
      product_special_tag_id: state => state.supplier.product_special_tag_id,
    }),
    suppliers() {
      let suppliers = this.supplier_items && this.supplier_items.hasOwnProperty(this.page_title) && Array.isArray(this.supplier_items[this.page_title]) && this.supplier_items[this.page_title].length ? this.supplier_items[this.page_title] : [];
      suppliers = suppliers.filter(supplier => supplier.status === 1).sort((a, b) => a.order - b.order);

      return suppliers;
    },
    suppliersWithBestsellers() {
      let suppliersWithBestsellers = []

      if (this.suppliers && this.suppliers.length && this.product_bestseller_tag_id) {
        for (let supplier of this.suppliers) {
          let supplier_products = this.suppliers_products.hasOwnProperty(supplier.id)
            ? this.suppliers_products[supplier.id] : []
          let supplier_bestsellers = []

          if (supplier_products) {
            for (let supplier_product_type in supplier_products) {
              for (let supplier_product of supplier_products[supplier_product_type]) {
                if (supplier_product.hasOwnProperty('tags') && supplier_product.tags.length
                  && supplier_product.tags.includes(this.product_bestseller_tag_id)) {
                  supplier_bestsellers.push(supplier_product)
                }
              }
            }
          }

          if (supplier_bestsellers.length) {
            suppliersWithBestsellers.push({ ...supplier, bestsellers: supplier_bestsellers })
          }
        }
      }

      return suppliersWithBestsellers
    },
    suppliersWithSpecials() {
      let suppliersWithSpecials = []

      if (this.suppliers && this.suppliers.length && this.product_special_tag_id) {
        for (let supplier of this.suppliers) {
          let supplier_products = this.suppliers_products.hasOwnProperty(supplier.id)
            ? this.suppliers_products[supplier.id] : []
          let supplier_specials = []

          if (supplier_products) {
            for (let supplier_product_type in supplier_products) {
              for (let supplier_product of supplier_products[supplier_product_type]) {
                if (supplier_product.hasOwnProperty('tags') && supplier_product.tags.length
                  && supplier_product.tags.includes(this.product_special_tag_id)) {
                  supplier_specials.push(supplier_product)
                }
              }
            }
          }

          if (supplier_specials.length) {
            suppliersWithSpecials.push({ ...supplier, specials: supplier_specials })
          }
        }
      }

      return suppliersWithSpecials
    }
  },
  created() {
    if (Object.keys(this.supplier_items).length === 0 && this.supplier_items.constructor === Object) {
      this.getAllSupplierItems({ params: { filter: true } });
    }
    this.getProductTags();
  },
  methods: {
    ...mapActions('supplier', {
      getAllSupplierItems: 'getAll',
      getProductTags: 'getProductTags',
    }),
    getApiURL() {
      return appConfig.apiUrl
    },
    /* <!-- ------------information popup-------------- --> */
    handleOpenInforPopup(item) {
      if (item.additional_details=="" || !item.additional_details) {
          this.inforData = item.additional_details_default;
      } else {
          this.inforData = item.additional_details;
      }
      this.$modal.show("inforPopup_"+this.modal_key);
    }, 
  }
}
</script>