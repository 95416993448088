<template>
   <v-card 
    elevation="2"
  >
    <router-link :to="{ name: supplier_router, params: { id: supplier_item.id } }">
      <div class="category-slider__img-wrapper"
        :style="{ 'background-color': supplier_item.logo_background_color }">
        <img class="category-slider__img" :src="supplier_item.image" alt="">
      </div>
    </router-link>
    <div v-for="(item, index) in supplier_item[item_key]" :key="index"
      class="category-slider__info position-relative">
      <img src="/assets/img/icons/info.SVG" alt="" class="infor-icon" @click="handleOpenInforPopup(item)"
          v-if="item.show_additional_details">
      <router-link
        :to="{ name: product_router, params: { id: item.id, supplier_id: item.supplier_id } }"
        class="d-flex justify-content-between align-items-center color-primary w-100 rtl-row-reverse"
        >

        <p class="fs14 mb-0 text-two-line">
          {{ item.name }}
        </p>

        <div v-if="item.price != null" class="font-weight-bold space-nowrap">
          <span class="fs20">
            {{ user_type_logged_in=="Reseller" ? item.price : item.customer_price }}
          </span>
          <span class="fs14">
            ₪
          </span>
        </div>
      </router-link>
    </div>
  </v-card>
</template>

<script>
  export default {
    name: "SellerCard",
    props: {
      supplier_item: Object, 
      supplier_router: String,
      product_router: String,
      item_key: String
    },
    methods: {
      handleOpenInforPopup(item) {
        this.$emit('openInforPopup', item);
      }, 
    }
  }
</script>

<style lang="scss" scoped>
  .category-slider__info {
    background-color: #fff;
    &:hover {
      background-color: #EEEEEE;
    }
  }
  .category-slider__img-wrapper {
    height: 80px;
    padding: 10px;
  }

  .infor-icon {
    position: absolute;
    top: 3px;
    right: 10px;
    cursor: pointer;
    width: 16px;
    height: 16px;
  }
  .rtl-type .infor-icon {
    left: 10px;
    right: unset;
  }
</style>